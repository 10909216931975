<template>
  <component
    :is="to ? NuxtLink : 'button'"
    :key="key"
    :to="to ? to : undefined"
    class="group relative inline-flex min-w-fit items-center justify-center rounded-full outline-none"
    :class="[
      {
        'bg-light-gray': color === 'blue-bordered',
        'bg-blue': color === 'blue-outline',
        'bg-orange': color === 'orange-outline'
      },
      size === 'default' && 'h-16 w-16 min-h-[64px] min-w-[64px]',
      size === 'sm' && 'h-12 w-12 min-w-[48px] min-h-[48px]',
      size === 'md' && 'h-20 w-20 min-h-[80px] min-w-[80px]',
      size === 'lg' && 'h-[136px] w-[136px] min-h-[136px] min-w-[136px]',
      size === 'xl' && 'h-72 w-72 min-h-[288px] min-w-[288px]'
    ]"
  >
    <span
      class="absolute inline-block h-full w-full rounded-full bg-cover transition-transform duration-500 ease-in-out group-hover:rotate-180"
      :class="{
        'bg-orange bg-[url(/assets/images/backgrounds/star-orange.svg)]': color === 'orange',
        'bg-blue bg-[url(/assets/images/backgrounds/star-blue.svg)]': color === 'blue'
      }"
    />
    <span
      class="absolute left-1/2 top-1/2 inline-flex h-[72%] w-[72%] -translate-x-1/2 -translate-y-1/2 items-center justify-center rounded-full"
      :class="[
        color === 'orange' && 'bg-blue-600',
        color === 'blue' && 'bg-blue-800',
        color === 'blue-bordered' && 'bg-blue-900'
      ]"
      :style="{
        background: color === 'blue-outline' || color === 'orange-outline' ? outlineColor : ''
      }"
    >
      <Icon :name="iconName" :size="iconSize ?? defaultIconSize" :color="iconColor ?? 'white'" />
    </span>
    <span class="sr-only">Details</span>
  </component>
</template>

<script setup lang="ts">
import { IconName, IconSize } from '~/components/media/Icon.vue'

interface Props {
  to?: string | object | undefined | null
  iconName: IconName
  iconSize?: IconSize
  iconColor?: string
  outlineColor?: string
  color?: 'blue' | 'orange' | 'blue-bordered' | 'blue-outline' | 'orange-outline'
  size?: 'default' | 'sm' | 'md' | 'lg' | 'xl'
}

const props = withDefaults(defineProps<Props>(), {
  color: 'orange',
  size: 'default',
  outlineColor: 'white'
})

const NuxtLink = resolveComponent('NuxtLink')
const key = ref(0)

const defaultIconSize = computed(() => {
  switch (props.size) {
    case 'md':
      return 'xs'
    case 'lg':
      return 'lg'
    case 'xl':
      return '2xl'
    default:
      return 'xs'
  }
})

onMounted(() => {
  key.value += 1
})
</script>
